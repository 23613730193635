import React, { useState, useEffect } from "react";
import "./Profile.scss";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { fetchUserAttributes, updateUserAttributes } from "aws-amplify/auth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  TextField,
} from "@mui/material";
import { Authenticator, useTheme, View, Image, Text, Heading } from '@aws-amplify/ui-react';
import logo from "./assets/indoorify-logo.svg"
Amplify.configure(awsconfig);

function Profile() {
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getUserDetails() {
      try {
        const user = await fetchUserAttributes();

        setUser(user);
      } catch (error) {
        console.error("Error fetching futsals:", error);
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    }

    getUserDetails();
  }, []);

  const [formName, setFormName] = useState();
  const [formNumber, setFormNumber] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateUserAttributes({
        userAttributes: {
          name: formName,
          phone_number: `+94${formNumber}`,
        },
      });

      handleClose();
    } catch (error) {
      console.error("Error updating user attributes:", error);
    }
  };

  const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Heading
            level={4}
          >
              Sign In For Free!<br/>Book Your Indoors Now.
          </Heading>
        </View>
      );
    },
    
    Footer() {
        const { tokens } = useTheme();
    
        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Text color={tokens.colors.neutral[80]}>
              &copy; All Rights Reserved
            </Text>
          </View>
        );
      },

      SignIn: {
        Header() {
          const { tokens } = useTheme();
    
          return (
            <View textAlign="center" padding={tokens.space.large}>
              <Image
                alt="indoortime logo"
                src={logo}
                width="250px"
              />
            </View>
          );
        }
      }
};

const formFields = {
    signUp: {
      name: {
        order: 1
      },
      email: {
        order:2
      },
      phone_number: {
        order: 3,
        dialCodeList: ['+94'],
        isRequired: true,
      },
      password: {
        order: 4
      },
      confirm_password: {
        order: 5
      }
    },
   }

  return (
    <div id="profile">
      <Authenticator
        variation="modal"
        formFields={formFields}
        components={components}
      >
        <div className="userInfo">
          {loading ? (
            <div className="userInfoWrap">
              <div className="headline">
                <Skeleton variant="rounded" width={100} height={100} />
                <span>
                  Hello there,
                  <br />
                  <span className="name">
                    <Skeleton
                      variant="rounded"
                      height={35}
                      style={{ width: "200px" }}
                    />
                  </span>
                </span>
              </div>
              <div className="gmail">
                Gmail:
                <br />
                <Skeleton variant="rounded" width={200} height={20} />
              </div>
              <div className="phone">
                Phone:
                <br />
                <Skeleton variant="rounded" width={200} height={20} />
              </div>
            </div>
          ) : (
            <div className="userInfoWrap">
              <div className="headline">
                <img alt="user-pic" src={user.picture}></img>
                <span>
                  Hello there,
                  <br />
                  <span className="name">{user.name}</span>
                </span>
              </div>
              <div className="gmail">
                Gmail:
                <br />
                {user.email}
              </div>
              <div className="phone">
                Phone:
                <br />
                {user.phone_number}
              </div>
            </div>
          )}

          <button id="editProfileBtn" onClick={handleClickOpen}>
            Edit Profile
          </button>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: handleSubmit,
            }}
          >
            <DialogTitle>Edit Profile</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please make sure that the given information is correct.
              </DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => setFormName(e.target.value)}
              />
              <TextField
                autoFocus
                required
                margin="dense"
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                placeholder="+94123456780"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => setFormNumber(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="myBookings">
          <h2>My Bookings</h2>
          <div className="bookingsWrapper">
            <div className="booking">
              <h4>ABC Futsal</h4>
              <div className="dateTime">
                <span>Date: 10/09/2024</span>
                <span>Time: 1.00 PM - 2.00PM </span>
              </div>
              <span className="refNo">Reference No: 823327897388</span>
            </div>
          </div>
        </div>
        <div className="bookingHistory">
          <h2>Booking History</h2>
          <div className="bookingsWrapper">
            <div className="booking">
              <h4>Classic Sports</h4>
              <div className="dateTime">
                <span>Date: 01/06/2024</span>
                <span>Time: 11.00 AM - 12.00PM </span>
              </div>
              <span className="refNo">Reference No: 823327897388</span>
            </div>
            <div className="booking">
              <h4>947 Sports Plus</h4>
              <div className="dateTime">
                <span>Date: 04/05/2024</span>
                <span>Time: 9.00 AM - 10.00PM </span>
              </div>
              <span className="refNo">Reference No: 823327897388</span>
            </div>
          </div>
        </div>
      </Authenticator>
    </div>
  );
}

export default Profile;
