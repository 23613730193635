import { Link } from 'react-router-dom';
import './NotFound.scss';
import errorImage from './assets/404-error.gif';

function NotFound () {
    return(
        <div id="NotFound">
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"20px"}}>
                <h1>Page is under development or not available at the moment</h1>
                <Link to='/'>
                    <button>HomePage</button>
                </Link>
            </div>
            <a href="https://storyset.com/online">
                <img alt="error-404" src={errorImage}></img>
            </a>
        </div>
    )
}

export default NotFound;