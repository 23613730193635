/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://6qw5cdtw6rd7ppj25bq2zdfpx4.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-i34iii5xjbdzdc52afty2jakr4",
    "aws_cognito_identity_pool_id": "ap-south-1:08eb60f5-0ca1-4219-bd5c-992d1485c28f",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_xGyuVKkqo",
    "aws_user_pools_web_client_id": "97iha747jq59718ef4k47repi",
    "oauth": {
        "domain": "indoortime4897f55f-4897f55f-dev.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://indoorify.lk/",
        "redirectSignOut": "https://indoorify.lk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "futsalmedias25221-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
