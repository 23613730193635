import './Home.scss';
import footballImage from "./assets/football-player-with-a-ball.jpg"
import cricketImage from "./assets/cricket-player-striking-the-ball.jpg"
import tennisImage from "./assets/badminton-player-waiting-to-play.jpg"
import basketballImage from "./assets/basketball-player-running.jpg"
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import { Authenticator, useTheme, View, Image, Text, Heading } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import logo from "./assets/indoorify-logo.svg"

Amplify.configure(awsconfig);


const sports = {
    sport01: {
        name: "Football",
        imgLocation: footballImage,
    },
    sport02: {
        name: "Cricket",
        imgLocation: cricketImage,
    },
    sport03: {
        name: "Badminton",
        imgLocation: tennisImage,
    },
    sport04: {
        name: "Basketball",
        imgLocation: basketballImage,
    }
}

function Home () {
    const components = {
        Header() {
          const { tokens } = useTheme();
      
          return (
            <View textAlign="center" padding={tokens.space.large}>
              <Heading
                level={4}
              >
                  Sign In For Free!<br/>Book Your Indoors Now.
              </Heading>
            </View>
          );
        },
        
        Footer() {
            const { tokens } = useTheme();
        
            return (
              <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                  &copy; All Rights Reserved
                </Text>
              </View>
            );
          },

          SignIn: {
            Header() {
              const { tokens } = useTheme();
        
              return (
                <View textAlign="center" padding={tokens.space.large}>
                  <Image
                    alt="indoortime logo"
                    src={logo}
                    width="250px"
                  />
                </View>
              );
            }
          }
    };

    const formFields = {
        signUp: {
          name: {
            order: 1
          },
          email: {
            order:2
          },
          phone_number: {
            order: 3,
            dialCodeList: ['+94'],
            isRequired: true,
          },
          password: {
            order: 4
          },
          confirm_password: {
            order: 5
          }
        },
       }

    return(
            <div id="homepage">
                <Authenticator variation="modal" formFields={formFields} components={components}>
                    <h1 className="headUpper">What is Your</h1>
                    <h1 className="headLower">Game Plan?</h1>

                    {/* ---------- CHOOSE THE SPORT SECTION ----------- */}
                    <div id="sportsChoice">
                        {Object.entries(sports).map(([key, item]) => (
                            <Link to={`/Football`} key={key}>
                                <div className="sportsItem" style={{
                                    backgroundImage:`url(${item.imgLocation})`}}>
                                    <div className="bgColor">
                                        <div>{item.name}</div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </Authenticator>
            </div>
    )

    
}

export default Home;