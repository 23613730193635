import "./BookingPage.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import React, { useState } from "react";
import TimeSlot from "./Timeslot";
import BookingBtn from "./BookingBtn";
import awsconfig from "./aws-exports";
import {
  Authenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
} from "@aws-amplify/ui-react";
import logo from "./assets/indoorify-logo.svg";
import { Amplify } from "aws-amplify";

Amplify.configure(awsconfig);

function BookingPage({ futsal }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeSelect = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleSubmit = () => {
    console.log(
      "Selected Date:",
      selectedDate ? selectedDate.format("DD-MM-YYYY") : "No date selected"
    );
    console.log("Selected Time Slot:", selectedTimeSlot);
  };

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Heading level={4}>
            Sign In For Free!
            <br />
            Book Your Indoors Now.
          </Heading>
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Image alt="indoortime logo" src={logo} width="250px" />
          </View>
        );
      },
    },
  };

  const formFields = {
    signUp: {
      name: {
        order: 1,
      },
      email: {
        order: 2,
      },
      phone_number: {
        order: 3,
        dialCodeList: ["+94"],
        isRequired: true,
      },
      password: {
        order: 4,
      },
      confirm_password: {
        order: 5,
      },
    },
  };

  return (
    <div id="sportsPlus">
      <Authenticator
        variation="modal"
        formFields={formFields}
        components={components}
      >
        <div className="sportsPlusDescription">
          <div id="payhere-modal"></div>
          <img
            src={`https://futsalmedias25221-dev.s3.ap-south-1.amazonaws.com/${futsal.name.replaceAll(
              " ",
              "-"
            )}.jpg`}
            alt={futsal?.name}
          ></img>
          <h1>{futsal.name}</h1>
          <span>Per Hour: Rs. {futsal.priceHour}</span>
        </div>

        <div id="bookingForm">
          <div className="date">
            <label>Date:</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                maxDate={dayjs("12-31-2024")}
                label={"Select Date"}
                format="DD-MM-YYYY"
                views={["day", "month", "year"]}
                disablePast
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </div>
          <div className="time">
            <TimeSlot onTimeSelect={handleTimeSelect} />
          </div>
          <div className="advanceCTA">
            <label>
              Advance for Booking: <span>Rs. 500</span>
            </label>
            <BookingBtn />
          </div>
        </div>

        <div className="otherDetails">
          <div className="note">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.1886 6.65985C16.4412 6.65985 16.6834 6.76018 16.862 6.93877C17.0405 7.11735 17.1409 7.35957 17.1409 7.61212V19.0394C17.1409 19.292 17.0405 19.5342 16.862 19.7128C16.6834 19.8913 16.4412 19.9917 16.1886 19.9917C15.936 19.9917 15.6938 19.8913 15.5152 19.7128C15.3367 19.5342 15.2363 19.292 15.2363 19.0394V7.61212C15.2363 7.35957 15.3367 7.11735 15.5152 6.93877C15.6938 6.76018 15.936 6.65985 16.1886 6.65985Z"
                fill="black"
              />
              <path
                d="M17.7758 24.4356C17.7758 24.8565 17.6086 25.2602 17.3109 25.5579C17.0133 25.8555 16.6096 26.0227 16.1887 26.0227C15.7678 26.0227 15.3641 25.8555 15.0664 25.5579C14.7688 25.2602 14.6016 24.8565 14.6016 24.4356C14.6016 24.0147 14.7688 23.611 15.0664 23.3133C15.3641 23.0157 15.7678 22.8485 16.1887 22.8485C16.6096 22.8485 17.0133 23.0157 17.3109 23.3133C17.6086 23.611 17.7758 24.0147 17.7758 24.4356Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8712 31.1015C23.9351 31.1015 30.4727 24.5638 30.4727 16.5C30.4727 8.43615 23.9351 1.89848 15.8712 1.89848C7.80737 1.89848 1.2697 8.43615 1.2697 16.5C1.2697 24.5638 7.80737 31.1015 15.8712 31.1015ZM15.8712 32.3712C24.6372 32.3712 31.7424 25.266 31.7424 16.5C31.7424 7.73401 24.6372 0.628784 15.8712 0.628784C7.10523 0.628784 0 7.73401 0 16.5C0 25.266 7.10523 32.3712 15.8712 32.3712Z"
                fill="black"
              />
            </svg>
            <p>
              <b>No Additional Charges Applied</b>
              <br />
              Pay the advance for booking and the rest to the futsal on the day
              of play
            </p>
          </div>
          <div className="location">
            <span>Location:</span>
            <iframe
              src={futsal.embedLink}
              title="googleMaps"
              loading="lazy"
            ></iframe>
          </div>
          <div className="contactNo">
            <span>
              Contact: <a href={`tel:${futsal.phone}`}>{futsal.phone}</a> /{" "}
              <a href={`tel:${futsal.telephone}`}>{futsal.telephone}</a>
            </span>
          </div>
        </div>
        <div className="phoneIcon">
          <a href={`tel:${futsal.phone}`}>
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none">
              <rect
                x="0.444946"
                y="0.834106"
                width="43"
                height="43"
                rx="21.5"
                fill="#1F2420"
              />
              <path
                d="M29.8949 31.3341C27.8116 31.3341 25.7533 30.8801 23.7199 29.9721C21.6866 29.0641 19.8366 27.7764 18.1699 26.1091C16.5033 24.4418 15.2159 22.5918 14.3079 20.5591C13.3999 18.5264 12.9456 16.4681 12.9449 14.3841C12.9449 14.0841 13.0449 13.8341 13.2449 13.6341C13.4449 13.4341 13.6949 13.3341 13.9949 13.3341H18.0449C18.2783 13.3341 18.4866 13.4134 18.6699 13.5721C18.8533 13.7308 18.9616 13.9181 18.9949 14.1341L19.6449 17.6341C19.6783 17.9008 19.6699 18.1258 19.6199 18.3091C19.5699 18.4924 19.4783 18.6508 19.3449 18.7841L16.9199 21.2341C17.2533 21.8508 17.6489 22.4464 18.1069 23.0211C18.5649 23.5958 19.0693 24.1501 19.6199 24.6841C20.1366 25.2008 20.6783 25.6801 21.2449 26.1221C21.8116 26.5641 22.4116 26.9681 23.0449 27.3341L25.3949 24.9841C25.5449 24.8341 25.7409 24.7218 25.9829 24.6471C26.2249 24.5724 26.4623 24.5514 26.6949 24.5841L30.1449 25.2841C30.3783 25.3508 30.5699 25.4718 30.7199 25.6471C30.8699 25.8224 30.9449 26.0181 30.9449 26.2341V30.2841C30.9449 30.5841 30.8449 30.8341 30.6449 31.0341C30.4449 31.2341 30.1949 31.3341 29.8949 31.3341Z"
                fill="#FFFFFF"
              />
            </svg>
          </a>
        </div>
      </Authenticator>
    </div>
  );
}

export default BookingPage;
