import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import "./Timeslot.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const TimeSlot = ({ startTime = "9:00 AM", endTime = "1:00 AM", interval = 1, onTimeSelect }) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setScroll(scrollType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const generateTimeSlots = (start, end, interval) => {
    const slots = [];
    let currentTime = moment(start, "h:mm A");
    let endTimeMoment = moment(end, "h:mm A");

    if (endTimeMoment.isBefore(currentTime)) {
      endTimeMoment.add(1, "day");
    }

    while (currentTime < endTimeMoment) {
      const startTimeFormatted = currentTime.format("h:mm A");
      currentTime.add(interval, "hours");
      const endTimeFormatted = currentTime.format("h:mm A");
      if (currentTime.isBefore(endTimeMoment)) {
        slots.push(`${startTimeFormatted} - ${endTimeFormatted}`);
      }
    }

    return slots;
  };

  const timeSlots = generateTimeSlots(startTime, endTime, interval);

  const handleTimeClick = (slot) => {
    setSelectedTimeSlot(slot);
    onTimeSelect(slot); // Call the parent callback function
    handleClose();
  };

  return (
    <div id="timeSlots" className="time">
      <label>Time:</label>
      <input
        placeholder="SELECT TIME"
        value={selectedTimeSlot}
        onClick={handleClickOpen("paper")}
        readOnly
      />
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Available Time Slots</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div id="timeSlots">
              {timeSlots.map((slot, index) => (
                <div
                  onClick={() => handleTimeClick(slot)}
                  className={`ATimeSlot ${
                    slot === selectedTimeSlot ? "selected" : ""
                  }`}
                  key={index}
                >
                  {slot}
                </div>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleClose}>Selected</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TimeSlot;


