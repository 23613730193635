import './Nav.scss'
import {ReactComponent as HomeNavComp} from './assets/home-nav.svg'
import {ReactComponent as Logo} from './assets/indoorify-logo.svg'
import {ReactComponent as ProfNavComp} from './assets/profile-nav.svg'
import { Link } from 'react-router-dom'

function Nav () {
    return(
        <div className='navbarOuterDiv'>
            <div className='navbar'>
                <nav>
                    <div>
                        <Link to='/'>
                            <HomeNavComp className='HomeNavComp'/>
                        </Link>
                    </div>
                    <div>
                        <Link to='/'>
                            <Logo/>
                        </Link>
                    </div>
                    <div>
                        <Link to='/Profile'>
                            <ProfNavComp className='ProfNavComp'/>
                        </Link>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Nav;
