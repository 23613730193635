/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($customerId: ID!) {
    getCustomer(customerId: $customerId) {
      customerId
      email
      name
      phone
      profilePic
      bookings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $customerId: ID
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      customerId: $customerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        customerId
        email
        name
        phone
        profilePic
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($bookingId: ID!) {
    getBooking(bookingId: $bookingId) {
      bookingId
      bookedDate
      bookedTimeslot
      amount
      customerId
      futsalId
      transactionDetails {
        date
        time
        referenceNo
        __typename
      }
      customer {
        customerId
        email
        name
        phone
        profilePic
        createdAt
        updatedAt
        owner
        __typename
      }
      futsal {
        futsalId
        adminId
        name
        priceHour
        rating
        phone
        telephone
        city
        embedLink
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $bookingId: ID
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBookings(
      bookingId: $bookingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bookingId
        bookedDate
        bookedTimeslot
        amount
        customerId
        futsalId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($adminId: ID!) {
    getAdmin(adminId: $adminId) {
      adminId
      name
      email
      futsal {
        futsalId
        adminId
        name
        priceHour
        rating
        phone
        telephone
        city
        embedLink
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $adminId: ID
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdmins(
      adminId: $adminId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        adminId
        name
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingsByCustomerId = /* GraphQL */ `
  query BookingsByCustomerId(
    $customerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        bookedDate
        bookedTimeslot
        amount
        customerId
        futsalId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingsByFutsalId = /* GraphQL */ `
  query BookingsByFutsalId(
    $futsalId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByFutsalId(
      futsalId: $futsalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        bookedDate
        bookedTimeslot
        amount
        customerId
        futsalId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFutsal = /* GraphQL */ `
  query GetFutsal($futsalId: ID!) {
    getFutsal(futsalId: $futsalId) {
      futsalId
      adminId
      name
      priceHour
      rating
      phone
      telephone
      city
      embedLink
      bookings {
        nextToken
        __typename
      }
      admin {
        adminId
        name
        email
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFutsals = /* GraphQL */ `
  query ListFutsals(
    $futsalId: ID
    $filter: ModelFutsalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFutsals(
      futsalId: $futsalId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        futsalId
        adminId
        name
        priceHour
        rating
        phone
        telephone
        city
        embedLink
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
