import { signOut } from "aws-amplify/auth";
import "./HamMenu.scss";
import { ReactComponent as HamburgerMenu } from "./assets/Hamburger-menu.svg";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

gsap.registerPlugin(useGSAP);

function HamMenu() {
  const { contextSafe } = useGSAP();

  const onEnter = contextSafe(({}) => {
    let tl = gsap.timeline();

    tl.to("#navSlide", { xPercent: "-100", duration: 0.5, ease: "expo.out" });
    tl.to(".backdrop", { opacity: 1, duration: 0.5 }, "<");
  });

  const onExit = contextSafe(({}) => {
    gsap.to("#navSlide", { xPercent: "100", duration: 0.3 });
    gsap.to(".backdrop", { opacity: 0 });
  });

  return (
    <div id="hamburgerMenu">
      <div className="hamburgerOuter">
        <HamburgerMenu onClick={onEnter} />
      </div>
      <div id="navSlide">
        <div className="backdrop" onClick={onExit}></div>
        <div className="navigation">
          <div className="stopCTA">
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              onClick={onExit}
            >
              <rect width="35" height="35" rx="17.5" fill="white" />
              <path
                d="M23.8 11.21C23.7075 11.1173 23.5976 11.0437 23.4766 10.9935C23.3556 10.9434 23.226 10.9175 23.095 10.9175C22.964 10.9175 22.8343 10.9434 22.7134 10.9935C22.5924 11.0437 22.4825 11.1173 22.39 11.21L17.5 16.09L12.61 11.2C12.5174 11.1074 12.4075 11.034 12.2865 10.9839C12.1656 10.9337 12.0359 10.908 11.905 10.908C11.7741 10.908 11.6444 10.9337 11.5235 10.9839C11.4025 11.034 11.2926 11.1074 11.2 11.2C11.1074 11.2926 11.034 11.4025 10.9839 11.5234C10.9338 11.6444 10.908 11.774 10.908 11.905C10.908 12.0359 10.9338 12.1656 10.9839 12.2865C11.034 12.4075 11.1074 12.5174 11.2 12.61L16.09 17.5L11.2 22.39C11.1074 22.4826 11.034 22.5925 10.9839 22.7134C10.9338 22.8344 10.908 22.964 10.908 23.095C10.908 23.2259 10.9338 23.3556 10.9839 23.4765C11.034 23.5975 11.1074 23.7074 11.2 23.8C11.2926 23.8926 11.4025 23.966 11.5235 24.0161C11.6444 24.0662 11.7741 24.092 11.905 24.092C12.0359 24.092 12.1656 24.0662 12.2865 24.0161C12.4075 23.966 12.5174 23.8926 12.61 23.8L17.5 18.91L22.39 23.8C22.4826 23.8926 22.5925 23.966 22.7135 24.0161C22.8344 24.0662 22.9641 24.092 23.095 24.092C23.2259 24.092 23.3556 24.0662 23.4765 24.0161C23.5975 23.966 23.7074 23.8926 23.8 23.8C23.8926 23.7074 23.966 23.5975 24.0161 23.4765C24.0662 23.3556 24.092 23.2259 24.092 23.095C24.092 22.964 24.0662 22.8344 24.0161 22.7134C23.966 22.5925 23.8926 22.4826 23.8 22.39L18.91 17.5L23.8 12.61C24.18 12.23 24.18 11.59 23.8 11.21Z"
                fill="black"
              />
            </svg>
          </div>
          <nav>
            <a href="/How-to-book">How to book?</a>
            <a href="/Register-your-futsal">Register Your Futsal</a>
            <a href="/FAQ">FAQ</a>
            <a onClick={signOut}>Logout</a>
          </nav>
          <div className="socialMedia">
            Follow us on
            <div className="icons">
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.54 0H18.46C22.62 0 26 3.38 26 7.54V18.46C26 20.4597 25.2056 22.3776 23.7916 23.7916C22.3776 25.2056 20.4597 26 18.46 26H7.54C3.38 26 0 22.62 0 18.46V7.54C0 5.54027 0.794391 3.62244 2.20842 2.20842C3.62244 0.794391 5.54027 0 7.54 0ZM7.28 2.6C6.03879 2.6 4.84841 3.09307 3.97074 3.97074C3.09307 4.84841 2.6 6.03879 2.6 7.28V18.72C2.6 21.307 4.693 23.4 7.28 23.4H18.72C19.9612 23.4 21.1516 22.9069 22.0293 22.0293C22.9069 21.1516 23.4 19.9612 23.4 18.72V7.28C23.4 4.693 21.307 2.6 18.72 2.6H7.28ZM19.825 4.55C20.256 4.55 20.6693 4.7212 20.974 5.02595C21.2788 5.3307 21.45 5.74402 21.45 6.175C21.45 6.60598 21.2788 7.0193 20.974 7.32405C20.6693 7.62879 20.256 7.8 19.825 7.8C19.394 7.8 18.9807 7.62879 18.6759 7.32405C18.3712 7.0193 18.2 6.60598 18.2 6.175C18.2 5.74402 18.3712 5.3307 18.6759 5.02595C18.9807 4.7212 19.394 4.55 19.825 4.55ZM13 6.5C14.7239 6.5 16.3772 7.18482 17.5962 8.40381C18.8152 9.62279 19.5 11.2761 19.5 13C19.5 14.7239 18.8152 16.3772 17.5962 17.5962C16.3772 18.8152 14.7239 19.5 13 19.5C11.2761 19.5 9.62279 18.8152 8.40381 17.5962C7.18482 16.3772 6.5 14.7239 6.5 13C6.5 11.2761 7.18482 9.62279 8.40381 8.40381C9.62279 7.18482 11.2761 6.5 13 6.5ZM13 9.1C11.9657 9.1 10.9737 9.51089 10.2423 10.2423C9.51089 10.9737 9.1 11.9657 9.1 13C9.1 14.0343 9.51089 15.0263 10.2423 15.7577C10.9737 16.4891 11.9657 16.9 13 16.9C14.0343 16.9 15.0263 16.4891 15.7577 15.7577C16.4891 15.0263 16.9 14.0343 16.9 13C16.9 11.9657 16.4891 10.9737 15.7577 10.2423C15.0263 9.51089 14.0343 9.1 13 9.1Z"
                  fill="white"
                />
              </svg>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26 13.0326C26 5.8386 20.176 0 13 0C5.824 0 0 5.8386 0 13.0326C0 19.3403 4.472 24.5925 10.4 25.8045V16.9424H7.8V13.0326H10.4V9.77444C10.4 7.25915 12.441 5.21303 14.95 5.21303H18.2V9.12281H15.6C14.885 9.12281 14.3 9.70927 14.3 10.4261V13.0326H18.2V16.9424H14.3V26C20.865 25.3484 26 19.7965 26 13.0326Z"
                  fill="white"
                />
              </svg>
              <svg
                width="21"
                height="23"
                viewBox="0 0 21 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6607 3.60333C15.7452 2.60618 15.2407 1.32561 15.2411 0H11.1027V15.8444C11.0708 16.7019 10.6913 17.514 10.0443 18.1099C9.39731 18.7057 8.53319 19.0388 7.63393 19.0389C5.73214 19.0389 4.15179 17.5567 4.15179 15.7167C4.15179 13.5189 6.375 11.8706 8.66518 12.5478V8.51C4.04464 7.92222 0 11.3467 0 15.7167C0 19.9717 3.69643 23 7.62054 23C11.8259 23 15.2411 19.7417 15.2411 15.7167V7.67944C16.9192 8.82925 18.934 9.44616 21 9.44278V5.49444C21 5.49444 18.4821 5.60944 16.6607 3.60333Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HamMenu;
