import CryptoJS from "crypto-js";
import { useEffect } from "react";

function BookingBtn() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.payhere.lk/lib/payhere.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function generateHash(
    merchant_id,
    order_id,
    amount,
    currency,
    merchant_secret
  ) {
    const secretHash = CryptoJS.MD5(merchant_secret).toString().toUpperCase();
    const stringToHash =
      merchant_id + order_id + amount + currency + secretHash;
    const hash = CryptoJS.MD5(stringToHash).toString().toUpperCase();
    return hash;
  }

  // Example usage
  const merchant_id = "1228090";
  const order_id = "12345";
  const amount = "500";
  const currency = "LKR";
  const merchant_secret =
    "Mzg1ODU4OTc4MjM1Njk5OTU0NDYzMTEyMDI5NDY5MzU0MTgxNTYwNg==";

  const hashValue = generateHash(
    merchant_id,
    order_id,
    amount,
    currency,
    merchant_secret
  );

  useEffect(() => {
    if (window.payhere) {
      // Payment completed. It can be a successful failure.
      window.payhere.onCompleted = function onCompleted(orderId) {
        console.log("Payment completed. OrderID:" + orderId);
        // Note: validate the payment and show success or failure page to the customer
      };

      // Payment window closed
      window.payhere.onDismissed = function onDismissed() {
        // Note: Prompt user to pay again or show an error page
        console.log("Payment dismissed");
      };

      // Error occurred
      window.payhere.onError = function onError(error) {
        // Note: show an error page
        console.log("Error:" + error);
      };
    }
  }, []);

  const payButton = document.getElementById("payhere-payment");

  if (payButton) {
    payButton.onclick = function (e) {
      window.payhere.startPayment(payment);
        console.log("cliked")
    };
  }

  // Put the payment variables here
  var payment = {
    sandbox: true,
    merchant_id: merchant_id, // Replace your Merchant ID
    return_url: undefined, // Important
    cancel_url: undefined, // Important
    notify_url: "http://sample.com/notify",
    order_id: order_id,
    items: "Classic Sports",
    amount: amount,
    currency: currency,
    hash: { hashValue }, // *Replace with generated hash retrieved from backend
    first_name: "Saman",
    last_name: "Perera",
    email: "samanp@gmail.com",
    phone: "0771234567",
    address: "No.1, Galle Road",
    city: "Colombo",
    country: "Sri Lanka",
    delivery_address: "No. 46, Galle road, Kalutara South",
    delivery_city: "Kalutara",
    delivery_country: "Sri Lanka",
    custom_1: "",
    custom_2: "",
  };

  return (
    <button type="submit" id="payhere-payment">
      BOOK NOW
    </button>
  );
}

export default BookingBtn;
