import "./Football.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import * as queries from "./graphql/queries";
import Skeleton from "@mui/material/Skeleton";
import logo from "./assets/indoorify-logo.svg";
import {
  Authenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

function Football() {
  const [searchQuery, setSearchQuery] = useState("");
  const [AllFutsals, setAllFutsals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function listAllFutsals() {
      try {
        const client = generateClient();

        // Simple query
        const futsalsQL = await client.graphql({
          query: queries.listFutsals,
          authMode: "apiKey",
        });

        if (futsalsQL?.data?.listFutsals?.items) {
          const names = futsalsQL.data.listFutsals.items;
          setAllFutsals(names);
        } else {
          console.error("Unexpected response structure:", futsalsQL);
        }
      } catch (error) {
        console.error("Error fetching futsals:", error);
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    }

    listAllFutsals();
  }, []);

  const filteredFutsals = AllFutsals.filter((item) => {
    const name = item.name || "";
    const estLocation = item.city || "";

    return (
      name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      estLocation.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Heading level={4}>
            Sign In For Free!
            <br />
            Book Your Indoors Now.
          </Heading>
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Image alt="indoortime logo" src={logo} width="250px" />
          </View>
        );
      },
    },
  };

  const formFields = {
    signUp: {
      name: {
        order: 1,
      },
      email: {
        order: 2,
      },
      phone_number: {
        order: 3,
        dialCodeList: ["+94"],
        isRequired: true,
      },
      password: {
        order: 4,
      },
      confirm_password: {
        order: 5,
      },
    },
  };

  return (
    <div id="football">
      <Authenticator
        variation="modal"
        formFields={formFields}
        components={components}
      >
        <h1 className="headUpper">Choose Best</h1>
        <h1 className="headLower">Among The Best!</h1>
        <div id="futsalList">
          {loading
            ? // Show loading skeletons when data is being fetched
              Array(5)
                .fill()
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    height={148}
                    style={{ maxWidth: "1000px" }}
                  />
                ))
            : // Show futsal items once data is loaded
              filteredFutsals.map((item, index) => (
                <Link
                  to={`/Football/${item.name.replaceAll(" ", "-")}`}
                  key={index}
                >
                  <div className="futsalItem">
                    <img
                      src={`https://futsalmedias25221-dev.s3.ap-south-1.amazonaws.com/${item.name.replaceAll(
                        " ",
                        "-"
                      )}.jpg`}
                      alt={item.name.replaceAll(" ", "-")}
                    ></img>
                    <div className="futsalDetail">
                      <h3>{item.name}</h3>
                      <span className="locationWithIcon">
                        <svg
                          width="11"
                          height="14"
                          viewBox="0 0 11 14"
                          fill="none"
                        >
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            d="M5.28049 13.7894C5.28049 13.7894 0.485504 7.53534 0.485504 4.8844C0.485504 4.25471 0.60953 3.63119 0.850501 3.04944C1.09147 2.46768 1.44467 1.93909 1.88992 1.49383C2.33518 1.04858 2.86377 0.695383 3.44553 0.454413C4.02728 0.213442 4.6508 0.0894165 5.28049 0.0894165C5.91017 0.0894165 6.53369 0.213442 7.11545 0.454413C7.6972 0.695383 8.2258 1.04858 8.67105 1.49383C9.11631 1.93909 9.4695 2.46768 9.71047 3.04944C9.95144 3.63119 10.0755 4.25471 10.0755 4.8844C10.0755 7.53534 5.28049 13.7894 5.28049 13.7894ZM5.28049 6.25439C5.64383 6.25439 5.9923 6.11006 6.24922 5.85313C6.50614 5.59621 6.65048 5.24774 6.65048 4.8844C6.65048 4.52105 6.50614 4.17259 6.24922 3.91567C5.9923 3.65874 5.64383 3.5144 5.28049 3.5144C4.91714 3.5144 4.56868 3.65874 4.31175 3.91567C4.05483 4.17259 3.91049 4.52105 3.91049 4.8844C3.91049 5.24774 4.05483 5.59621 4.31175 5.85313C4.56868 6.11006 4.91714 6.25439 5.28049 6.25439Z"
                            fill="black"
                          />
                        </svg>
                        {item.city}
                      </span>
                      <span className="rating">
                        {Array(parseInt(item.rating))
                          .fill()
                          .map((_, index) => (
                            <svg
                              key={index} // Add a unique key for each star
                              width="14" // Adjusted width to fit multiple stars in the container
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M7 0.522705L8.51667 4.11125H12.4167L9.46458 7.00916L10.3448 10.8144L7 8.79666L3.65521 10.8144L4.53542 7.00916L1.58333 4.11125H5.48333L7 0.522705Z"
                                fill="black"
                              />
                            </svg>
                          ))}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
        </div>

        <div className="searchBarWrapper">
          <svg width="18" height="17" viewBox="0 0 18 17" fill="none">
            <path
              d="M16.0637 16.8793L10.3151 11.1308C9.85888 11.4957 9.33421 11.7847 8.74111 11.9976C8.148 12.2105 7.51687 12.317 6.84773 12.317C5.19007 12.317 3.78729 11.7427 2.6394 10.5942C1.49151 9.44573 0.917265 8.04295 0.916657 6.3859C0.916049 4.72885 1.4903 3.32608 2.6394 2.17758C3.78851 1.02908 5.19129 0.454834 6.84773 0.454834C8.50417 0.454834 9.90725 1.02908 11.057 2.17758C12.2067 3.32608 12.7806 4.72885 12.7788 6.3859C12.7788 7.05505 12.6723 7.68618 12.4594 8.27928C12.2465 8.87239 11.9576 9.39706 11.5926 9.8533L17.3412 15.6019L16.0637 16.8793ZM6.84773 10.492C7.98832 10.492 8.95797 10.093 9.75669 9.29486C10.5554 8.49676 10.9545 7.5271 10.9539 6.3859C10.9532 5.2447 10.5542 4.27536 9.75669 3.47785C8.95919 2.68035 7.98953 2.281 6.84773 2.27978C5.70592 2.27856 4.73657 2.67792 3.93968 3.47785C3.14279 4.27779 2.74343 5.24714 2.7416 6.3859C2.73978 7.52467 3.13914 8.49432 3.93968 9.29486C4.74022 10.0954 5.70957 10.4945 6.84773 10.492Z"
              fill="black"
            />
          </svg>

          <input
            type="text"
            placeholder="Search by name or location..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="searchBar"
          />
        </div>
      </Authenticator>
    </div>
  );
}

export default Football;
